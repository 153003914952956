import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/marketdefence/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/marketdefence/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/marketdefence/${data.id}`, data);
    else return axios.post(`/api/marketdefence`, data);
  },
  delete: function (id) {
    return axios.delete(`/api/marketdefence/${id}`);
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/marketdefence/ExportExcel', 'marketdefence.xlsx', request);
  },
  changeStatusMulti: function (data) {
    return axios.patch(`/api/marketdefence/changestatusmulti`, data);
  },
};
